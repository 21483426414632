import React, { ComponentClass, useEffect } from 'react'

import 'mobx-react/batchingForReactDom'

import 'react-icecream/dist/icecream.min.css'
import './global.less'

type Props = {
  Component: ComponentClass
  pageProps: any
}

function boot() {
  if (typeof window !== 'object') return

  // const uaParser = new UAParser()
  // const isWx = uaParser.getBrowser().name === 'WeChat'
  const { protocol, host } = window.location

  // niulink.io 域名强制 https 访问
  if (protocol === 'http:' && host.includes('niulink.io')) {
    window.location.protocol = 'https:'
  }

  // 非微信环境下 niulinkcloud.com 重定向到 niulink.io 域名
  // if (!isWx && host.includes('niulinkcloud.com')) {
  //   window.location.host = 'www.niulink.io'
  // }
}

export default function MyApp({ Component, pageProps }: Props) {
  useEffect(() => {
    boot()
    // 用来标识客户端渲染完成，如隔壁 `global.less` 会依赖之
    window.document.body.classList.add('mounted')
  }, [])

  return <Component {...pageProps} />
}
